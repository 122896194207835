<style scoped>
/* local styles */
.list-group-item {
  padding: 5px 5px;
}
.horizontal-scrollable > .row {
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-scrollable > .row > .col-xs-2 {
  min-width: 180px;
  display: inline-block;
  float: none;
  vertical-align: top;
  white-space: normal;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
<template>
  <div class="box" :id="'ambulatorio_' + id" :style="{ 'border-top-color': color }">
    <div class="box-header with-border" id="calendar_head" style="padding-bottom: 5px">
      <div class="text-center">
        <div class="pull-left">
          <!-- Buttons, labels, and many other things can be placed here! -->
          <!-- Here is a label for example -->
          <button class="btn btn-box-tool"><i class="fa fa-search-plus"></i></button>
          <button class="btn btn-box-tool"><i class="fa fa-search-minus"></i></button>
        </div>
        <!-- /.box-tools -->
        <h2 class="box-title" id="title">{{ name }}</h2>
        <div class="pull-right text-right">
          <!-- Buttons, labels, and many other things can be placed here! -->
          <!-- Here is a label for example -->
          <!--button class="btn btn-box-tool" id="availability" data-toggle='tooltip' title='Aggiungi disponibilità'><i class="fa fa-plus"></i><i class="fa fa-user-md"></i></button-->
          <button class="btn btn-box-tool" @click="refresh()"><i class="fa fa-refresh"></i></button>
        </div>
        <!-- /.box-tools -->
      </div>
    </div>
    <!-- /.box-header -->
    <div class="box-body horizontal-scrollable">
      <div class="row">
        <div class="col-xs-2" style="padding: 0px 2px" v-for="d in diffDays" :key="'day_' + d">
          <ul class="list-group" style="margin-bottom: 5px">
            <li class="list-group-item active text-center" :style="{ 'margin-bottom': '10px', 'background-color': color, 'border-color': color }">
              <button
                class="btn btn-link pull-left"
                style="color: white; padding: 0px"
                v-if="canAddAvail"
                data-toggle="tooltip"
                title="Aggiungi disponibilità"
                @click="newAvailability(getIndexDay(d - 1))">
                <i class="fa fa-plus"></i> <i class="fa fa-user-md"></i>
              </button>
              {{ getHeaderDay(d - 1) }}
              <button
                class="btn btn-link pull-right"
                style="color: white; padding: 0px"
                data-toggle="tooltip"
                title="Aggiungi nota"
                @click="newNote(getIndexDay(d - 1))">
                <i class="fa fa-plus"></i> <i class="fa fa-sticky-note"></i>
              </button>
            </li>
            <li
              class="list-group-item"
              v-for="note in getDailyNotes(getIndexDay(d - 1))"
              :key="'note_' + d + '_' + note.id"
              @click.prevent="openNote(note)">
              <div :style="{ width: '85%', display: 'inline-block', color: note.warning ? 'red' : 'black' }">
                <i class="fa fa-sticky-note"></i> {{ note.text }}
              </div>
              <div class="pull-right" style="display: inline-block">
                <i class="fa fa-circle" :style="{ color: note.doctor.color }" aria-hidden="true" data-toggle="tooltip" :title="note.doctor.name"></i>
              </div>
            </li>

            <li
              class="list-group-item"
              :style="{ 'background-color': appColor(app) }"
              style="min-height: 70px"
              v-for="app in getDailyAppointments(getIndexDay(d - 1))"
              :key="'app_' + d + '_' + app.id"
              @click.prevent="openAppointment(app)">
              <div style="width: 100% !important">
                <div class="row" style="margin-right: 0px; margin-left: 0px">
                  <div class="col-xs-10" style="padding-left: 0px; padding-right: 0px">
                    <div style="width: 100%; font-weight: bold">
                      <i class="fa fa-clock-o" aria-hidden="true"></i> {{ app.objAppointment.time_start.substring(0, 5) }}
                    </div>
                  </div>
                  <div class="col-xs-2 pull-right" style="padding-left: 0px; padding-right: 0px">
                    <span class="pull-right" v-if="app.objAppointment.doctor" v-html="app.objAppointment.doctor.icon_hex"></span>
                  </div>
                </div>
                <div class="row" style="margin-right: 0px; margin-left: 0px">
                  <div class="col-xs-10" style="padding-left: 0px; padding-right: 0px">
                    <div
                      :style="{ display: 'inline-block', color: app.objAppointment.warning ? 'red' : 'black' }"
                      v-if="app.objAppointment && app.objAppointment.patient">
                      <i class="fa fa-medkit" aria-hidden="true"></i> {{ app.objAppointment.patient }}
                      <br v-if="app.objAppointment.telephone" />
                      <i class="fa fa-phone" aria-hidden="true" v-if="app.objAppointment.telephone"> {{ app.objAppointment.telephone }}</i>
                      <br v-if="app.objAppointment.cellular" />
                      <i class="fa fa-mobile" aria-hidden="true" v-if="app.objAppointment.cellular"> {{ app.objAppointment.cellular }}</i>
                      <br v-if="app.objAppointment.email" />
                      <i class="fa fa-envelope-o" aria-hidden="true" v-if="app.objAppointment.email"> {{ app.objAppointment.email }}</i>
                    </div>
                    <div style="display: inline-block" v-else>
                      <p style="color: green; margin: 0px">{{ app.objAppointment.doctor.name.match(/\b(\w)/g).join("") }}</p>
                    </div>
                  </div>
                  <div class="col-xs-2 pull-right" style="padding-left: 0px; padding-right: 0px">
                    <div class="pull-right" style="display: inline-block" v-if="app.objAppointment.doctor">
                      <i
                        class="fa fa-circle"
                        :style="{ color: app.objAppointment.color }"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        :title="app.objAppointment.doctor.name"></i>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-right: 0px; margin-left: 0px">
                  <div class="col-xs-10" style="padding-left: 0px; padding-right: 0px"></div>
                  <div class="col-xs-2 pull-right" style="padding-left: 0px; padding-right: 0px">
                    <div class="pull-right">
                      <i v-if="app.objAppointment.note" class="fa fa-comments-o" aria-hidden="true"></i>
                      <strong>{{ app.objAppointment.note }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /.box-body -->

    <div class="overlay" v-if="loading">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
  </div>
</template>

<script>
import Appointment from "./modals/Appointment.vue";
import Note from "./modals/Note.vue";
import Availability from "./modals/Availability.vue";
export default {
  props: {
    id: {
      type: Number,
      default: 1,
    },
    color: String,
    name: String,
    start: String,
    end: String,
    doctors: Array,
    buffer: Object,
    action: String,
    user: Object,
  },
  mounted() {
    //console.log('Component mounted.')
    this.getAppointments();
    this.getNotes();
    $('[data-toggle="tooltip"]').tooltip();
  },
  data: function () {
    return {
      loading: true,
      appointments: [],
      notes: [],
    };
  },
  methods: {
    getHeaderDay(id) {
      return this.$moment(this.start).add(id, "d").format("ddd DD MMM").toUpperCase();
    },
    getIndexDay(id) {
      return this.$moment(this.start).add(id, "d").format("YYYY-MM-DD");
    },
    getAppointments() {
      this.appointments = [];
      let url = "/appointments/events/" + this.id + "?start=" + this.start + "&end=" + this.end;
      if (!this.canSeeOther) {
        url = "/appointments/events/" + this.id + "/" + this.user.doctor_id + "?start=" + this.start + "&end=" + this.end;
      }

      axios
        .get(url)
        .then((res) => {
          //console.log(res)
          this.appointments = res.data;
          this.loading = false;
          $('[data-toggle="tooltip"]').tooltip();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNotes() {
      let vm = this;
      this.notes = [];
      let url = "/notes/calendar/" + this.id + "?start=" + this.start + "&end=" + this.end;
      if (!this.canSeeOther) {
        url = "/notes/calendar/" + this.id + "/" + this.user.doctor_id + "?start=" + this.start + "&end=" + this.end;
      }

      axios
        .get(url)
        .then((res) => {
          //console.log(res)
          vm.notes = res.data;
          vm.loading = false;
          $('[data-toggle="tooltip"]').tooltip();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDailyAppointments(day) {
      var retArray = [];
      let vm = this;
      retArray = this.appointments.filter(function (el) {
        return vm.$moment(el.start).isSame(day, "day") && !el.allDay;
      });

      retArray = retArray.sort((a, b) => vm.$moment(a.date).format("YYYYMMDD") - vm.$moment(b.date).format("YYYYMMDD"));

      //console.log("Ambulatorio "+this.id+" giorno "+day);
      //console.log(retArray);
      return retArray;
    },
    getDailyNotes(day) {
      var retArray = [];
      let vm = this;
      retArray = this.notes.filter(function (el) {
        return vm.$moment(el.date).isSame(day, "day") && !el.allDay;
      });

      retArray = retArray.sort((a, b) => vm.$moment(a.created_at).format("YYYYMMDD") - vm.$moment(b.created_at).format("YYYYMMDD"));
      return retArray;
    },
    openAppointment(appointment) {
      let vm = this;
      this.$modal.show(
        Appointment,
        { name: "appointment", appointment: appointment, buffer: this.buffer, action: this.action, canWrite: this.canWrite },
        { height: "auto", adaptive: true, width: "1000px" },
        {
          clone: (event, action) => this.$emit("clone", event, action),
          move: (event, action) => this.$emit("move", event, action),
          update: () => {
            vm.loading = true;
            vm.getAppointments();
          },
        }
      );
    },
    newNote(date) {
      let vm = this;
      this.$modal.show(
        Note,
        {
          name: "note",
          doctors: this.doctors,
          ambulatory_id: this.id,
          date: date,
        },
        { height: "auto", adaptive: true },
        {
          update: () => {
            vm.loading = true;
            vm.getNotes();
          },
        }
      );
    },
    openNote(note) {
      let vm = this;
      this.$modal.show(
        Note,
        {
          name: "note",
          doctors: this.doctors,
          note: note,
          ambulatory_id: note.ambulatory_id,
          date: note.date,
          edit: true,
        },
        { height: "auto", adaptive: true },
        {
          update: () => {
            vm.loading = true;
            vm.getNotes();
          },
        }
      );
    },
    newAvailability(date) {
      let vm = this;
      this.$modal.show(
        Availability,
        {
          name: "availability",
          doctors: this.doctors,
          ambulatory_id: this.id,
          date: date,
        },
        { height: "auto", adaptive: true },
        {
          update: () => {
            vm.loading = true;
            vm.getAppointments();
          },
        }
      );
    },
    appColor(app) {
      if (app.mooving == true) return "#f0ad4e";
      if (app.cloning == true) return "#bee5eb";
      return "#FFF";
    },
    refresh() {
      this.loading = true;
      this.getAppointments();
      this.getNotes();
    },
  },
  computed: {
    diffDays: function () {
      const oneDay = 24 * 60 * 60 * 1000;
      let localStart = this.$moment(this.start);
      let localEnd = this.$moment(this.end);
      return localEnd.diff(localStart, "d");
    },
    canAddAvail: function () {
      if (this.user.role == "admin") return true;
      return this.user.can_add_avail;
    },
    canSeeOther: function () {
      if (this.user.role == "admin") return true;
      return this.user.can_see_others;
    },
    canWrite: function () {
      if (this.user.role == "admin") return true;
      return this.user.can_write > 0;
    },
  },
  watch: {
    start: function (newVal, oldVal) {
      // watch it
      //console.log('start changed: ', newVal, ' | was: ', oldVal)
    },
    end: function (newVal, oldVal) {
      // watch it
      //console.log('end changed: ', newVal, ' | was: ', oldVal)
      this.getAppointments();
      this.getNotes();
    },
  },
};
</script>
